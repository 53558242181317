@import "./animations";
@import "./mixins";
@import "./overrides";
@import "./variables";

#root {
	#introduction {
		flex-direction: unset;

		.introduction-container {
			.content {
				margin: 3rem auto 2rem auto;

				.title {
					color: var(--foreground-primary);
				}
			}
		}
	}

	#about {
		.about-container {
			.about-description {
				color: var(--foreground-primary);
				font-size: 18px;
				font-weight: normal;
			}

			.tech-stack {
				span,
				svg,
				i {
					display: inline-block;
					vertical-align: middle;
				}

				svg,
				i {
					width: 36px;
					height: 36px;
					padding: 4px;

					display: inline-block;
					vertical-align: middle;
				}
				svg *,
				i * {
					width: 36px;
					height: 36px;
				}
			}

			.columns {
				padding: 12px 0px 12px 0px;
				h6 {
					color: var(--accent);
					text-align: center;
				}

				.column {
					@include breakpoint(extra_small_devices) {
						text-align: center;
					}

					.btn-alt {
						font-weight: bold;
						background-color: transparent;
						color: var(--foreground-primary);

						@include breakpoint(extra_small_devices) {
							border-radius: 4px;
							background-color: var(--background-primary);
						}

						@include breakpoint(small_devices) {
							border: none !important;
							margin: 3px !important;
						}

						&:hover,
						&:focus {
							color: var(--foreground-primary);
							transform: translateX(0.5rem);
						}
					}
				}
			}

			h1 {
				color: inherit !important;
				font-size: 36px !important;
				border-bottom: var(--accent) solid 4px;
				display: inline-block;
			}
		}
	}

  .discord {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 100;

    .svgicon {
      position: fixed;
      width: 50px;
      bottom: 15px;
      left: 15px;
      z-index: 150;
    }
  }

	#projects {
		background-color: var(--background-secondary);
		padding: 6rem 1.5rem;

		transform: skewY(-3deg);

		.projects-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			transform: skewY(3deg);

			h1 {
				color: inherit !important;
				font-size: 36px;
				font-weight: bold;

				align-items: center;
				margin-bottom: 1rem;
			}
		}
	}
}
