@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css");
@import url("https://cdn.jsdelivr.net/gh/devicons/devicon@v2.14.0/devicon.min.css");

:root {
	--accent: #a3b8ef;
	--background-primary: #13171b;
	--background-secondary: #171b20;
	--background-secondary-alt: #21262e;
	--foreground-primary: #b6beca;
}

.blue-text {
	font-weight: bold;
	color: var(--accent);
}

.hyperlink {
	font-weight: bold;
	transition: 0.4s linear;
	transform: translateY(-0rem);

	&:hover {
		transform: translateY(-0.5rem);
	}
}

.box {
	color: var(--foreground-primary);
	background-color: var(--background-primary);
	border: 3px solid var(--background-secondary-alt);

	display: inline-block;
	width: 100%;
	height: 100%;

	span {
		font-size: 0.875rem;
		font-weight: bold;
		padding: 8px;
	}
}
