@mixin breakpoint($point) {
	@if $point == extra_small_devices {
		@media only screen and (max-width: 600px) {
			@content;
		}
	} @else if $point == small_devices {
		@media only screen and (min-width: 600px) {
			@content;
		}
	} @else if $point == medium_devices {
		@media only screen and (max-width: 768px) {
			@content;
		}
	} @else if $point == large_devices {
		@media only screen and (min-width: 992px) {
			@content;
		}
	} @else if $point == extra_large_devices {
		@media only screen and (min-width: 1200px) {
			@content;
		}
	} @else if $point == potrait_tablet {
		@media only screen and (min-width: 361px) {
			@content;
		}
	} @else if $point == landscape_tablet {
		@media only screen and (min-width: 768px) {
			@content;
		}
	}
}
